<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20" type="flex" align="bottom" justify="space-between">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="4" :offset="0">
              <el-input v-model="from_id" placeholder="请输入转让方ID" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="from_mobile"
                placeholder="请输入转让方手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="from_email"
                placeholder="请输入转让方邮箱"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="badge_code"
                placeholder="请输入badge_code"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="nft_tx_id" placeholder="请输入NFT txid" size="normal"></el-input>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4" :offset="0">
              <el-input v-model="to_id" placeholder="请输入受赠方ID" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input
                v-model="to_mobile"
                placeholder="请输入受赠方手机号"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="to_email" placeholder="请输入受赠方邮箱" size="normal"></el-input>
            </el-col>
            <el-col :span="4" :offset="0">
              <el-input v-model="nft_name" placeholder="请输入NFT名称" size="normal"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0" style="text-align: right">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="transfer_txid">
      <el-table-column label="NFT作品名" prop="nft_name"> </el-table-column>
      <el-table-column label="预览图" prop="pics" width="320px">
        <template slot-scope="scope">
          <el-image
            v-for="(pic, idx) in scope.row.pics"
            :key="idx"
            :src="pic"
            fit="fill"
            :lazy="true"
            :preview-src-list="scope.row.pics"
            style="max-width: 140px; max-height: 140px; margin-right: 8px"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="badge_code" prop="badge_code"> </el-table-column>
      <el-table-column label="交易txid" prop="transfer_txid"></el-table-column>
      <el-table-column label="受赠方信息" prop="to_info"></el-table-column>
      <el-table-column label="转让方信息" prop="from_info"></el-table-column>
      <el-table-column label="订单创建时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNFTTransferOrders } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 查询参数
      txid: "",
      from_id: "",
      from_mobile: "",
      from_email: "",
      to_id: "",
      to_mobile: "",
      to_email: "",
      nft_tx_id: "",
      nft_name: "",
      badge_code: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const badge_code = search.get("badge_code");
    if (badge_code) {
      this.badge_code = badge_code;
    }
    // this.fetchTransferOrders();
  },
  methods: {
    getSearchParams() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };

      this.to_id && (params.to_id = this.to_id);
      this.to_mobile && (params.to_mobile = this.to_mobile);
      this.to_email && (params.to_email = this.to_email);

      this.from_id && (params.from_id = this.from_id);
      this.from_mobile && (params.from_mobile = this.from_mobile);
      this.from_email && (params.from_email = this.from_email);

      this.nft_tx_id && (params.nft_tx_id = this.nft_tx_id);
      this.nft_name && (params.nft_name = this.nft_name);
      this.badge_code && (params.badge_code = this.badge_code);

      return params;
    },
    async fetchTransferOrders() {
      try {
        const params = this.getSearchParams();
        const { data } = await getNFTTransferOrders(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },

    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchTransferOrders();
    },

    handleClickReset() {
      this.resetQuery();
      this.fetchTransferOrders();
    },

    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.txid = "";
      this.from_id = "";
      this.from_mobile = "";
      this.from_email = "";
      this.to_id = "";
      this.to_mobile = "";
      this.to_email = "";
      this.nft_tx_id = "";
      this.nft_name = "";
      this.badge_code = "";
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchTransferOrders();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchTransferOrders();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchTransferOrders();
    },
  },
};
</script>

<style lang="less" scoped></style>
